.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color: #1a1a1a;
  padding: 20px;
}

h1 {
  font-size: 6rem;
  margin: 0;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

h2 {
  font-size: 2rem;
  margin: 10px 0;
  color: #e0e0e0;
}

p {
  font-size: 1.2rem;
  color: #b0b0b0;
  margin: 20px 0;
}

.home-link {
  display: inline-block;
  padding: 12px 24px;
  background-color: #4a4a4a;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  border: 1px solid #666;
}

.home-link:hover {
  background-color: #666;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}